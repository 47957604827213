html {
  font-size: 62.5%; /* =10px */
  background: #2d0b0b;
  height: 100%;
}

body {
  height: 100%;
  color: #f8e5d6ff;
  margin: 0;
  font-size: 1.6rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:-webkit-full-screen {
  background-color: #000;
}

input,
option,
select {
  margin: 0;
  padding: 0;
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
